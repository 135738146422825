
import React from "react";
import "./BlogLeft.scss";
function Blog({ title, image, description}) {
  return (
    <div className="leftalign">
        <div className="left">
        <img
            src={image}
            alt="blog"
          />
        </div>
        <div className="right">
        <div className="heading">{title}</div>
          <div className="desc">
           {description}
          </div>
        </div>
      </div> 
    
  );
}

export default Blog;
