import "./NewBlog.scss";

import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const NewBlog = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [input, setInput] = useState({
    title: "",
    image: "",
    description: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(input);
    axios
      .post(
        "http://localhost:8889/api/admin/blog/add",
        {
          ...input,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        alert("Blog posted successfully");
        navigate("/blogs");
      })
      .catch((err) => {
        console.log(err.message);
        alert("failed to add blog");
      });
  };

  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Add new blog</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                input.image
                  ? input.image
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt="URL.createObjectURL(file)"
            />
          </div>
          <div className="right">
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
              <div className="formInput">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Enter blog title"
                  name="title"
                  value={input.title}
                  onChange={handleChange}
                />
              </div>
              <div className="formInput">
                <label>Image</label>
                <input
                  type="text"
                  placeholder="Enter image url"
                  name="image"
                  value={input.image}
                  onChange={handleChange}
                />
              </div>
              <div className="formInput">
                <textarea
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Blog description"
                  name="description"
                  value={input.description}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button type="submit">post</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBlog;
