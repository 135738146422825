import "./NewUser.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

import SignUp from "../../components/auth/SignUp";

const NewUser = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <SignUp />
      </div>
    </div>
  );
};

export default NewUser;
