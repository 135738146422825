import React from 'react'
import './BlogRequest.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import RequestTable from '../../components/blog/RequestTable'

function BlogRequest() {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <RequestTable/>
      </div>
    </div>
  )
}

export default BlogRequest