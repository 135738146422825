import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import SignIn from "../../components/auth/SignIn";

const NewUser = () => {
  const userExist = localStorage.getItem("token");
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        {!userExist ? <SignIn /> : <AlreadyLogin />}
      </div>
    </div>
  );
};

export default NewUser;

const AlreadyLogin = () => {
  return (
    <>
      <h2>You are already logged in</h2>
    </>
  );
};
