import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function RequestTable() {
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [postId, setPostId] = React.useState("");
  const cancelRequest = () => {
    handleClose();
  };
  const approveRequest = (id) => {
    axios
      .patch(
        `http://localhost:8889/api/admin/blog/approve/${id}`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        alert("Blog approved");
      })
      .catch((err) => {
        console.log(err.message);
        alert("blog approve failed");
      });
    handleClose();
  };

  const handleClickOpen = (id) => {
    setPostId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    axios
      .get("http://localhost:8889/api/admin/blog/request")
      .then((res) => {
        console.log(res.data);
        setData(res.data.blogRequests);
      })
      .catch((err) => console.log(err.message));
  }, []);
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 450, width: "80vw", margin: "auto" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left" width={150}>
                Blog Title
              </TableCell>
              <TableCell align="center" width={150}>
                Username
              </TableCell>
              <TableCell align="center" width={300}>
                Email
              </TableCell>
              <TableCell align="right" width={200}>
                Requested At
              </TableCell>
              <TableCell align="center" width={150}>
                Image
              </TableCell>
              <TableCell align="center" width={100}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ height: "120px", maxHeight: "100px" }}
                  >
                    {row.username}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ overflow: "hidden", maxHeight: "100px" }}
                  >
                    {row.email}
                  </TableCell>
                  <TableCell align="right">
                    {new Date(row.createdAt).toDateString()}
                  </TableCell>
                  <TableCell align="center">
                    <img
                      src={row.image}
                      alt="jhssg"
                      style={{ height: "80px", width: "80px" }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button>View</Button>&nbsp;
                    <Button onClick={() => handleClickOpen(row._id)}>
                      Approve
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Post Confirmation dialog ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              By Clicking on approve you are going to approve the post.And the
              post will be available on the website
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelRequest}>cancel</Button>
            <Button onClick={() => approveRequest(postId)} autoFocus>
              Approve
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
