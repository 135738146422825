import React, { useEffect, useState } from "react";
import "./Blog.scss";
import Blog from "../../components/blog/Blog";
import BlogLeft from "../../components/blog/BlogLeft";
import axios from "axios";
import { Link } from "react-router-dom";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    axios
      .get("http://localhost:8889/api/general/blog/all")
      .then((res) => setBlogs(res.data.allblogs))
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="main">
      <div className="header">
        <Link to="/">Dashboard</Link>
        <Link to="new">New Blog</Link>
        <Link to="requests">Blog Requests</Link>
      </div>
      <div className="heading">Hansraj Blogs</div>
      <div className="company">
        <div className="picture">
          <img
            src="https://img.freepik.com/premium-photo/attentive-woman-watching-media-laptop_109710-3268.jpg"
            alt="lady with lapy"
          />
        </div>
        <div className="details">
          <div className="logo">
            <img
              src="https://media.licdn.com/dms/image/C560BAQH9I-2Mc9Y-Hw/company-logo_200_200/0/1631278059712?e=1697673600&v=beta&t=xVmS4AdilWSw_Q7lmAyguC57VaSYE9tFK6h06G-tcpc"
              alt="hv logo"
            />
          </div>
          <div className="head">Hansraj Ventures</div>
          <div className="desc">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam ad
            quis sapiente odit animi modi quae illum cupiditate quasi numquam
            vero hic dolorum, aliquam rerum repellat incidunt enim tenetur, eos
            et soluta ducimus quo unde? Commodi quaerat odi quae illum
            cupiditate quasi numquam vero hic dolorum, aliquam rerum repellat
            incidunt enim tenetur, eos et soluta ducimus quo unde? Commodi
            quaerat delectus incidunt dicta.
          </div>
        </div>
      </div>
      <div className="aboutus">
        <div className="left">
          <div className="heading">About us</div>
          <div className="desc">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur
            incidunt laborum corrupti ratione maiores cupiditate, iusto soluta
            eos accusamus dignissimos error veritatis molestias id minus. Harum
            dolor, blanditiis autem saepe illum laudantium similique natus. Cum
            adipisci, illum ducimus ad molestias alias suscipit blanditiis qui
            vel quas dolore. Quia cupiditate excepturi quisquam temporibus
            aperiam, quos recusandae debitis ullam. Temporibus enim recusandae
            quod. Id totam quibusdam aspernatur, optio fugiat blanditiis ad,
            ratione est obcaecati maiores in delectus omnis ut amet laboriosam
            suscipit ex, rerum temporibus nisi. Porro maiores explicabo voluptas
            veritatis alias consectetur doloribus debitis ad quam, facilis
            sapiente incidunt unde libero.
          </div>
        </div>
        <div className="right">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoVIwfawj9neDpBEDIiPj2kjZIykPxNyBWAg&usqp=CAU"
            alt="blog"
          />
        </div>
      </div>
      <div className="chooseus">
        <div className="left">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5f73g-d9DEtKWA19Jjfk28KfuSzJgRt1eDg&usqp=CAU"
            alt="blog"
          />
        </div>
        <div className="right">
          <div className="heading">Why We Choose Us ?</div>
          <div className="desc">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur
            incidunt laborum corrupti ratione maiores cupiditate, iusto soluta
            eos accusamus dignissimos error veritatis molestias id minus. Harum
            dolor, blanditiis autem saepe illum laudantium similique natus. Cum
            adipisci, illum ducimus ad molestias alias suscipit blanditiis qui
            vel quas dolore. Quia cupiditate excepturi quisquam temporibus
            aperiam, quos recusandae debitis ullam. Temporibus enim recusandae
            quod. Id totam quibusdam aspernatur, optio fugiat blanditiis ad,
            ratione est obcaecati maiores in delectus omnis ut amet laboriosam
            suscipit ex, rerum temporibus nisi. Porro maiores explicabo voluptas
            veritatis alias consectetur doloribus debitis ad quam, facilis
            sapiente incidunt unde libero.
          </div>
        </div>
      </div>
      {blogs &&
        blogs.map((blog, i) => {
          if (i % 2 === 0) {
            return (
              <Blog
                key={blog._id}
                title={blog.title}
                image={blog.image}
                description={blog.description}
              />
            );
          } else {
            return (
              <BlogLeft
                key={blog._id}
                title={blog.title}
                image={blog.image}
                description={blog.description}
              />
            );
          }
        })}
    </div>
  );
}
export default Blogs;
