import React from "react";
import "./Blog.scss";
function Blog({ title, image, description}) {
  return (
    <div className="rightalign">
      <div className="left">
        <div className="heading">{title}</div>
        <div className="desc">{description}</div>
      </div>
      <div className="right">
        <img src={image} alt="blog" />
      </div>
    </div>
  );
}

export default Blog;
