import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { Button } from "@mui/material";


export default function ChartRequest() {
  const [data, setData] = React.useState([]);
  
  const handleReply = () => {
   
  };

  React.useEffect(() => {
    axios.get(`http://localhost:8889/api/admin/getqueries`).then((res)=>{
        setData(res.data.allQueries)
      console.log(res.data);
    }).catch(err=>console.log(err.message))
  }, []);
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 450, width: "80vw", margin: "auto" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left " width={150}>
                Subject
              </TableCell> 
              <TableCell align="left" width={150}>
                Name
              </TableCell>
              <TableCell align="left" width={300}>
                Email
              </TableCell>
              <TableCell align="left" width={200}>
                Mobile No.
              </TableCell>
              <TableCell align="left" width={300}>
                Message
              </TableCell> 
              <TableCell align="left " width={200}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.subject}
                  </TableCell>
                  <TableCell
                    align="left "
                    style={{ height: "80px", maxHeight: "100px" }}
                  >
                    {row.name}
                  </TableCell> 
                  <TableCell  
                    align="left "
                    style={{ overflow: "hidden", maxHeight: "100px" }}
                  >
                    {row.email}{" "}
                  </TableCell>
                  <TableCell align="left "> 
                    {row.mobile}
                  </TableCell>
                  <TableCell align="left">
                    {row.message}
                  </TableCell>
                  <TableCell align="left "> 
                    <Button>View</Button>&nbsp;
                    <Button onClick={() => handleReply}>Reply</Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
